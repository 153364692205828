<template>
  <v-app>
    <b-container>
      <b-row class="d-flex align-items-center mt-2">
        <b-col cols="12" md="6">
          <h2 class="my-4 text-primary font-weight-bold">
            ما همیشه <span class="text-secondary">پاسخگو</span> هستیم
          </h2>

          <!-- <p class="mb-5 d-none">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است
          </p> -->
          <img
            src="@/assets/images/divider.png"
            alt="divider"
            class="my-4 d-block"
          />

          <div class="d-flex align-items-center">
            <img src="@/assets/images/tell.png" alt="tell" class="me-3" />
            <span class="">09122945346 &nbsp; ( پشتیبانی کاربران و متخصصین )</span>
          </div>
          <div class="d-flex align-items-center my-5">
            <img src="@/assets/images/question.png" alt="tell" class="me-3" />
            <span class="text-danger">برای نوبت گیری از متخصصین لطفا وارد <span class="text-success" role="button" v-on:click="Download()">وب اپلیکیشن مایندولوژی</span> شوید و اقدام به انتخاب متخصص مورد نظر کنید</span>
          </div>
          <!-- <div class="d-flex align-items-center my-5">
            <img src="@/assets/images/mail.png" alt="mail" class="me-3" />
            <span class="">hi@mindology.io</span>
          </div> -->

<!--          <div class="d-flex align-items-center">-->
<!--            <img-->
<!--              src="@/assets/images/location.png"-->
<!--              alt="location"-->
<!--              class="me-3"-->
<!--            />-->
<!--            <span class=""-->
<!--              >تهران، پایین تر از میدان رسالت، بلوار برات محمدی، پ13</span-->
<!--            >-->
<!--          </div>-->

          <div class="d-flex align-items-start mt-10 justify-content-start">
            <b-link
              href="https://www.instagram.com/mindology.io"
              target="_blank"
            >
              <v-btn
                icon
                fab
                depressed
                elevation="4"
                color="secondary"
                class="me-3"
                ><img
                  src="@/assets/images/instagram.png"
                  alt="instagram"
                  height="20"
              /></v-btn>
            </b-link>
            <!-- <v-btn
              icon
              fab
              depressed
              elevation="4"
              color="secondary"
              class="mx-3"
              ><img src="@/assets/images/youtube.png" alt="youtube" height="20"
            /></v-btn>
            <v-btn
              icon
              fab
              depressed
              elevation="4"
              color="secondary"
              class="mx-3"
              ><img src="@/assets/images/twitter.png" alt="twitter" height="20"
            /></v-btn>
            <v-btn
              icon
              fab
              depressed
              elevation="4"
              color="secondary"
              class="ms-3"
              ><img
                src="@/assets/images/telegram.png"
                alt="telegram"
                height="20"
            /></v-btn> -->
          </div>
        </b-col>
        <!-- <b-col cols="12" md="6">
          <b-card class="text-center curve-25">
            <b-card-body>
              <h3 class="text-center font-weight-bold">تماس با ما</h3>
              <b-form @submit="onSubmit">
                <b-form-group
                  id="input-group-1"
                  label="نام و نام خانوادگی"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.fullName"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="شماره همراه"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.phoneNumber"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  label="پیام شما"
                  label-for="input-3"
                >
                  <b-form-textarea
                    id="input-3"
                    v-model="form.message"
                    rows="3"
                    required
                  ></b-form-textarea>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="secondary"
                  class="rounded-lg px-6 py-2 text-white"
                  >ارسال پیام</b-button
                >
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col cols="12" md="12">
          <l-map
            :zoom="zoom"
            :center="center"
            style="height: 500px; width: 100%"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker :lat-lng="center" :icon="icon"></l-marker>
          </l-map>
        </b-col> -->
      </b-row>
    </b-container>
  </v-app>
</template>
<script>
import { icon } from "leaflet";

export default {
  data: () => ({
    zoom: 16,
    center: [35.734599608760846, 51.48463625460863],
    url:
      "https://developers.parsijoo.ir/web-service/v1/map/?type=tile&x={x}&y={y}&z={z}" +
      "&apikey=6e5445026e854fdfac79b819c0cdd813",
    attribution: "",
    icon: icon({
      iconUrl: require("@/assets/images/marker.png"),
      iconSize: [25, 41],
      iconAnchor: [25, 41],
    }),

    form: {
      fullName: "",
      phoneNumber: "",
      message: "",
    },
  }),

  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    Download(){
      this.$router.push("/download")
    }
  },
};
</script>
